// @flow
import React from 'react'

import VendorHeaderDescription from './VendorHeaderDescription'

type VendorHeaderProps = {
  companyName: ?string,
  companyAddress: ?string,
  companyPhone: ?string,
  companyUrl: ?string,
  businessContactName: ?string,
  businessContactPhone: ?string,
  businessContactEmail: ?string,
  description: ?string,
}

const VendorHeader = ({
  companyName,
  companyAddress,
  companyPhone,
  companyUrl,
  businessContactName,
  businessContactPhone,
  businessContactEmail,
  description,
}: VendorHeaderProps) => (
  <>
    <div className="flex flex-col">
      <div className="mb-4 flex flex-row items-center">
        <img src="https://via.placeholder.com/280x82" alt={companyName} />
        <div className="ml-4 bg-light-blue rounded font-bold text-xs text-blue-800 uppercase px-2 py-1">
          Seller since 2018
        </div>
      </div>
      <div className="text-sm text-gray-900">
        {companyAddress}
        {' | '}
        {companyPhone && companyPhone !== 'N/A' && (
          companyPhone
        )}
        {' | '}
        {companyUrl && (
          <a className="text-bluish" href={companyUrl}>{companyUrl.replace(/https?:\/\//, '')}</a>
        )}
      </div>
      <div className="text-sm text-gray-900">
        <span className="font-bold">
          Business Contact
          {' '}
        </span>
        {businessContactName}
        {' | '}
        {businessContactPhone && businessContactPhone !== 'N/A' && (
          businessContactPhone
        )}
        {' | '}

        {businessContactEmail && (
          <a className="text-bluish" href={`mailto:${businessContactEmail}`}>{businessContactEmail}</a>
        )}
      </div>
      <div className="mt-8">
        <VendorHeaderDescription description={description} />
      </div>
    </div>
  </>
)

export default VendorHeader
