// Product Detail Container
import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GET_SBIR } from './graphql'

import { metafieldValueForKey, parseBoolean } from './helpers'

import PDPPresenter from './presenter'

const ProductDetailContainer = () => {
  const params = useParams()
  const contract = _.get(params, 'contract')

  const variables = {
    contract: contract || '',
  }

  const { data, error, loading } = useQuery(GET_SBIR, { variables })

  if (loading) {
    return (
      <div style={{ padding: 40 }}>Loading...</div>
    )
  }

  if (error) {
    return (
      <div style={{ padding: 40 }}>
        <h4>Error Loading Products</h4>
        <p>{error.message}</p>
        <p>{error.description}</p>
      </div>
    )
  }

  const NotFoundMarkup = (
    <div style={{ padding: 40 }}>Not Found</div>
  )

  if (!data) return NotFoundMarkup

  const { sbirs } = data
  const sbir = sbirs[0]

  if (!sbir) return NotFoundMarkup

  const {
    _id,
    Agency: agency,
    Branch: branch,
    Contract: contractNumber,
    Abstract: description,
    Phase: phase,
    Program: program,
    Title: title,
    Award_Amount: rdCost,
    Agency_Tracking_Number: trackingNumber,
    Solicitation_Number: solicitNumber,
    Solicitation_Year: solicitYear,
    Topic_Code: solicitCode,
    Proposal_Award_Date: awardStart,
    DUNS: duns,
    company: {
      Woman_Owned: womanOwned,
      Name: companyName,
      Address1: companyAddress,
      Hubzone_Owned: hubzoneOwned,
      Socially_Economically_Disadvantaged: sedStatus,

    },
  } = sbir

  // TODO: remove previous values not available in Strapi
  const institution = ''
  // const companyName = ''
  // const companyAddress = ''
  // const hubzoneOwned = false
  const principalName = ''
  const principalPhone = ''
  const principalEmail = ''
  const businessContactName = ''
  const businessContactEmail = ''
  const businessContactPhone = ''

  const tags = []
  // let tags = _.get(sbir, 'tags.nodes')
  // tags = tags.map((tag) => tag.displayTitle)

  // Detail metafields
  // const agency = metafieldValueForKey(metafields, 'agency')
  // const branch = metafieldValueForKey(metafields, 'branch')
  // const contractNumber = metafieldValueForKey(metafields, 'contractNumber')
  // const institution = metafieldValueForKey(metafields, 'researchInst')
  // const phase = metafieldValueForKey(metafields, 'phase')
  // const program = metafieldValueForKey(metafields, 'program')
  // const rdCost = metafieldValueForKey(metafields, 'rdCost')
  // const trackingNumber = metafieldValueForKey(metafields, 'trackingNumber')
  // const sbirUrl = metafieldValueForKey(metafields, 'sbirUrl')

  // Solicitation metafields
  // const solicitNumber = metafieldValueForKey(metafields, 'solicitation-number')
  // const solicitYear = metafieldValueForKey(metafields, 'solicitation-year')
  // const solicitCode = metafieldValueForKey(metafields, 'solicitation-topicCode')

  // Award metafields
  // const awardYear = metafieldValueForKey(metafields, 'award-year')
  // const awardStart = metafieldValueForKey(metafields, 'award-start')
  // const awardEnd = metafieldValueForKey(metafields, 'award-end')

  // Company metafields
  // const companyName = metafieldValueForKey(metafields, 'company-name')
  // const companyAddress = metafieldValueForKey(metafields, 'company-address')
  // const duns = metafieldValueForKey(metafields, 'company-duns')
  // const hubzoneOwned = metafieldValueForKey(metafields, 'company-hubzoneOwned')
  // const womanOwned = parseBoolean(metafieldValueForKey(metafields, 'company-womanOwned'))

  // const sedStatus = metafieldValueForKey(metafields, 'company-sedStatus')

  // const principalName = metafieldValueForKey(metafields, 'company-principal-name')
  // const principalPhone = metafieldValueForKey(metafields, 'company-principal-phone')
  // const principalEmail = metafieldValueForKey(metafields, 'company-principal-email')

  // const businessContactName = metafieldValueForKey(metafields, 'company-businessContact-name')
  // const businessContactPhone = metafieldValueForKey(metafields, 'company-businessContact-phone')
  // const businessContactEmail = metafieldValueForKey(metafields, 'company-businessContact-email')

  const videoUrl = '' // _.find(metafields, ['key', 'videoUrl'])?.value

  const features = [] // _.find(metafields, ['key', 'features'])?.value
  // features = features ? features.split(',').map((feature) => feature.trim()) : features

  const press = [] // _.find(metafields, ['key', 'press'])?.value

  // press = [] // press ? press.split(',').map((link) => link.trim()) : press

  return (
    <PDPPresenter
      agency={agency.replaceAll('_', ' ')}
      awardStart={awardStart}
      branch={branch.replaceAll('_', ' ')}
      businessContactName={businessContactName}
      businessContactPhone={businessContactPhone}
      businessContactEmail={businessContactEmail}
      companyName={companyName}
      companyAddress={companyAddress}
      contractNumber={contractNumber}
      description={description}
      duns={duns}
      features={features}
      hubzoneOwned={hubzoneOwned}
      institution={institution}
      phase={phase.replaceAll('_', ' ')}
      press={press}
      program={program}
      principalName={principalName}
      principalPhone={principalPhone}
      principalEmail={principalEmail}
      rdCost={rdCost}
      sedStatus={sedStatus}
      solicitCode={solicitCode}
      solicitNumber={solicitNumber}
      solicitYear={solicitYear}
      tags={tags}
      title={title}
      trackingNumber={trackingNumber}
      videoUrl={videoUrl}
      womanOwned={womanOwned}
    />
  )
}

export default ProductDetailContainer
