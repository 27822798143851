// @flow strict
import React from 'react'

import Breadcrumbs from './components/Breadcrumbs'
import VendorHeader from './components/VendorHeader'
import { VendorProduct } from './components/VendorProduct'
import type { VendorProductProps } from './components/VendorProduct'
import VendorContactSidebar from './components/VendorContactSidebar'

type VendorPresenterProps = {
  businessContactName: ?string,
  businessContactPhone: ?string,
  businessContactEmail: ?string,
  companyName: ?string,
  companyAddress: ?string,
  companyPhone: ?string,
  companyUrl: ?string,
  description: ?string,
  title: ?string,
  products: Array<VendorProductProps>,
}

const VendorPresenter = ({
  businessContactName,
  businessContactPhone,
  businessContactEmail,
  companyName,
  companyAddress,
  companyPhone,
  companyUrl,
  description,
  products,
  title,
}: VendorPresenterProps) => (
  <>
    <div
      className="w-full bg-off-white"
    >
      <Breadcrumbs
        title={title}
      />
    </div>
    <div
      className="w-full bg-off-white flex"
    >
      <div className="w-3/4">
        <div className="ml-8 mb-16">
          <div className="w-full p-8">
            <VendorHeader
              businessContactName={businessContactName}
              businessContactPhone={businessContactPhone}
              businessContactEmail={businessContactEmail}
              companyName={companyName}
              companyAddress={companyAddress}
              companyPhone={companyPhone}
              companyUrl={companyUrl}
              description={description}
            />
            <div className="mt-10">
              <h2 className="font-bold text-lg uppercase">Products</h2>
              <div className="bg-white shadow-outside mt-4 divide-y w-full">
                { products.map((product) => (
                  <VendorProduct
                    title={product.title}
                    agency={product.agency}
                    branch={product.branch}
                    phase={product.phase}
                  />
                )) }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-1/4 mr-8">
        <VendorContactSidebar companyName={companyName} />
      </div>
    </div>
  </>
)

export default VendorPresenter
