// @flow strict
import React from 'react'

import Breadcrumbs from '../components/Breadcrumbs'
import ProductHeader from './components/ProductHeader'
import ProductContact from './components/ProductContact'
import ProductFeatures from './components/ProductFeatures'
import ProductSpecs from './components/ProductSpecs'
import ProductDetails from './components/ProductDetails'
import ProductPress from './components/ProductPress'
import ProductVideo from './components/ProductVideo'
import SimilarTechnologies from './components/SimilarTechnologies'
import ProductSidebar from './components/ProductSidebar'

type ProductDetailPresenterProps = {
  agency: ?string,
  awardStart: ?string,
  branch: ?string,
  businessContactName: ?string,
  businessContactPhone: ?string,
  businessContactEmail: ?string,
  companyName: ?string,
  companyAddress: ?string,
  contractNumber: ?string,
  description: ?string,
  duns: ?string,
  features: ?string[],
  hubzoneOwned: ?string,
  institution: ?string,
  phase: ?string,
  press: ?string[],
  principalName: ?string,
  principalPhone: ?string,
  principalEmail: ?string,
  program: ?string,
  rdCost: ?string,
  sedStatus: ?string,
  solicitCode: ?string,
  solicitNumber: ?string,
  solicitYear: ?string,
  tags: string[],
  title: ?string,
  trackingNumber: ?string,
  videoUrl: ?string,
  womanOwned: ?boolean,
}

const PDPPresenter = ({
  agency,
  awardStart,
  branch,
  businessContactName,
  businessContactPhone,
  businessContactEmail,
  companyName,
  companyAddress,
  contractNumber,
  description,
  duns,
  features,
  hubzoneOwned,
  institution,
  phase,
  press,
  principalName,
  principalPhone,
  principalEmail,
  program,
  rdCost,
  sedStatus,
  solicitCode,
  solicitNumber,
  solicitYear,
  tags,
  title,
  trackingNumber,
  videoUrl,
  womanOwned,
}: ProductDetailPresenterProps) => (
  <>
    <div
      className="w-full bg-off-white"
    >
      <Breadcrumbs
        title={title}
      />
    </div>
    <div
      className="w-full bg-off-white flex"
    >
      <div className="w-3/4">
        <div className="ml-16 mb-16 bg-white">
          <div className="shadow-outside w-full p-8 rounded">
            <ProductHeader
              agency={agency}
              branch={branch}
              contractNumber={contractNumber}
              description={description}
              phase={phase}
              program={program}
              tags={tags}
              title={title}
            />
            <ProductContact />

            { features
              ? (
                <ProductFeatures
                  features={features}
                />
              ) : null}

            <ProductSpecs />
            <ProductDetails
              contractNumber={contractNumber}
              duns={duns}
              hubzoneOwned={hubzoneOwned}
              institution={institution}
              rdCost={rdCost}
              sedStatus={sedStatus}
              solicitCode={solicitCode}
              solicitNumber={solicitNumber}
              solicitYear={solicitYear}
              trackingNumber={trackingNumber}
              womanOwned={womanOwned}
            />
            <ProductPress
              press={press}
            />
            { videoUrl
              ? (
                <ProductVideo
                  videoUrl={videoUrl}
                />
              ) : null}

          </div>
        </div>
        <div className="ml-12 mb-16">
          <SimilarTechnologies />
        </div>
      </div>
      <div className="w-1/4">
        <ProductSidebar
          companyName={companyName}
          companyAddress={companyAddress}
          businessContactName={businessContactName}
          businessContactPhone={businessContactPhone}
          businessContactEmail={businessContactEmail}
          principalName={principalName}
          principalPhone={principalPhone}
          principalEmail={principalEmail}
          phase={phase}
          rdCost={rdCost}
          awardStart={awardStart}
        />
      </div>
    </div>
  </>
)

export default PDPPresenter
