// @flow strict
import * as React from 'react'
import { ThreeDots } from 'svg-loaders-react'

import type { SearchResult, SearchResultSet } from './types'

type SearchResultsProps = {
  loading: boolean,
  resultClicked: (slug: string) => void,
  resultSet: ?SearchResultSet,
  searchValue: string,
}

type SearchResultListItemProps = {
  result: SearchResult,
  resultClicked: (contractNumber: string) => void,
  searchValue: string,
}

const SearchResultsListItem = (props: SearchResultListItemProps) => {
  const { result, resultClicked, searchValue } = props
  const { name, slug, vendor, metafields } = result
  const { contractNumber } = metafields

  if (!contractNumber) return null;

  const match = name.toLowerCase().indexOf(searchValue)

  // search value doesn't match result, render without any bold highlight
  if (match < 0) {
    return (
      <li
        className="h-10 flex items-center overflow-hidden whitespace-no-wrap cursor-pointer"
        onClick={() => resultClicked(contractNumber)}
      >
        <span className="font-normal text-base text-dark-grey">{name}</span>
      </li>
    )
  }

  // the search value matches a portion of the result, highlight that section in bold
  return (
    <li
      className="w-full h-10 flex flex-col overflow-hidden whitespace-no-wrap cursor-pointer my-2"
      onClick={() => resultClicked(contractNumber)}
      onKeyUp={(event) => { if (event.key === 'Enter') resultClicked(slug) }}
      role="menuitem"
      tabIndex="0"
    >
      <div>
        <span className="font-normal text-base text-dark-grey whitespace-pre">
          {name.substr(0, match)}
        </span>
        <span className="font-bold text-base text-dark-grey whitespace-pre">
          {name.substr(match, searchValue.length)}
        </span>
        <span className="font-normal text-base text-dark-grey whitespace-pre">
          {name.substr(match + searchValue.length, name.length - match - searchValue.length)}
        </span>
      </div>
      <div className="text-xs text-gray-500 whitespace-pre">
        {vendor}
      </div>
    </li>
  )
}

type SearchResultsListProps = {
  children?: React.Node,
}

const SearchResultsList = (props: SearchResultsListProps) => {
  const { children } = props
  return (
    <ul
      className="absolute w-full px-5 bg-white max-h-70 overflow-scroll"
      style={{ boxShadow: '0 8px 9px 0 rgba(0, 0, 0, 0.1)' }}
    >
      {children}
    </ul>
  )
}

SearchResultsList.defaultProps = {
  children: null,
}

const SearchResults = (props: SearchResultsProps) => {
  const {
    loading,
    searchValue,
    resultClicked,
    resultSet,
  } = props

  // Loading State
  if (loading) {
    return (
      <SearchResultsList>
        <li className="h-10 flex flex-col items-center justify-center" key="loading">
          <ThreeDots fill="#646464" width={40} />
        </li>
      </SearchResultsList>
    )
  }

  // Error State
  if (resultSet && resultSet.error) {
    return (
      <SearchResultsList>
        <li className="flex flex-col items-center justify-center" key="search-error">
          <span className="pt-2 font-normal text-base text-grey-text">Search Failed</span>
          <span className="pt-2 pb-2 font-normal text-base text-red-500">{resultSet.error.message}</span>
        </li>
      </SearchResultsList>
    )
  }

  // Empty State
  if (!resultSet || (resultSet && resultSet.results.length === 0)) {
    return (
      <SearchResultsList>
        <li className="h-10 flex flex-col items-center justify-center" key="no-results">
          <span className="font-normal text-base text-grey-text">No results found.</span>
        </li>
      </SearchResultsList>
    )
  }

  // Normal State
  return (
    <SearchResultsList>
      {resultSet.results.map((result) => (
        <SearchResultsListItem
          key={result.slug}
          result={result}
          resultClicked={resultClicked}
          searchValue={searchValue}
        />
      ))}
    </SearchResultsList>
  )
}

export default SearchResults
