// @flow strict
import React from 'react'

export type VendorProductProps = {
  agency: ?string,
  branch: ?string,
  phase: ?string,
  title: ?string,
}

export const VendorProduct = ({
  agency,
  branch,
  phase,
  title,
}: VendorProductProps) => (
  <div className="p-6">
    <div className="uppercase text-gray-900 text-sm">
      {agency}
      {' '}
      |
      {' '}
      {branch}
      {' '}
      |
      {' '}
      {phase}
    </div>
    <div className="text-lg">{ title }</div>
  </div>
)
