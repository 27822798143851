// @flow strict
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import logger from 'utils/logger'

import type { SearchResultSet, SearchFilter, SearchSort } from './types'
import { searchRequest } from './searchApi'

export const PLP_PAGE_SIZE = 10
export const DEFAULT_SORT = { name: 'Relevance', field: '_score', order: 'desc' }

export type SearchContext = {
  currentPage: number,
  currentResultLoading: boolean,
  currentResultSet: ?SearchResultSet,
  currentSearchValue: string,
  currentFilters: SearchFilter,
  currentSort: SearchSort,
  previewResultLoading: boolean,
  previewResultSet: ?SearchResultSet,
  searchForTerm: (term: string, page?: number) => void,
  searchForCategory: (category: string) => void,
  previewSearchValue: string,
  setPreviewSearchValue: (value: string) => void,
  setCurrentFilters: (filter: SearchFilter) => void,
  setCurrentSort: (sort: SearchSort) => void,
}

export const Context = createContext<SearchContext>({
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: null,
  currentSearchValue: '',
  currentFilters: {},
  currentSort: {},
  previewResultLoading: false,
  previewResultSet: null,
  searchForTerm: () => { },
  searchForCategory: () => { },
  previewSearchValue: '',
  setPreviewSearchValue: () => {},
  setCurrentFilters: () => {},
  setCurrentSort: () => {},
})

export const useSearch = () => useContext(Context)

type SearchProviderProps = {
  children?: React.Node,
}

const SearchProvider = (props: SearchProviderProps) => {
  const { children } = props

  const history = useHistory()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentResultLoading, setCurrentResultLoading] = useState<boolean>(false)
  const [currentResultSet, setCurrentResultSet] = useState<?SearchResultSet>(null)
  const [currentSearchValue, setCurrentSearchValue] = useState<string>('')
  const [currentFilters, setCurrentFilters] = useState<SearchFilter>({})
  const [currentSort, setCurrentSort] = useState<SearchSort>(DEFAULT_SORT)
  const [previewResultLoading, setPreviewResultLoading] = useState<boolean>(false)
  const [previewResultSet, setPreviewResultSet] = useState<?SearchResultSet>(null)
  const [previewSearchValue, setPreviewSearchValue] = useState<string>('')

  // Get results from ElasticSearch every time searchValue or currentFilter changes
  const previewTerm = useRef()
  const previewFilters = useRef()
  previewTerm.current = previewSearchValue
  previewFilters.current = currentFilters

  useEffect(() => {
    if (!previewSearchValue) {
      setPreviewResultSet(null)
      setPreviewResultLoading(false)
      return
    }

    setPreviewResultLoading(true)
    searchRequest(previewSearchValue, currentFilters, currentSort)
      .then((result) => {
        if ((previewSearchValue !== previewTerm.current)
            && (currentFilters !== previewFilters.current)
        ) {
          // handle race condition of API calls coming back out of order by
          // only processing the result that matches the current search term and filters
          logger.log('SearchProvider: no change')
          return
        }

        logger.log(`Found ${result.results.length} results for preview term ${previewSearchValue}`)
        setPreviewResultLoading(false)
        setPreviewResultSet(result)
        setCurrentResultSet(result)
      })
      .catch((error) => {
        logger.error(`ElasticSearch error:\n${error}`)
        setPreviewResultLoading(false)
        setPreviewResultSet({
          error,
          results: [],
          searchValue: previewSearchValue,
          totalResults: 0,
        })
      })
  }, [previewSearchValue, currentFilters, currentSort])

  const searchForTerm = (term: string, page: number = 1) => {
    setCurrentSearchValue(term)
    setCurrentPage(page)
    if (!previewSearchValue) setPreviewSearchValue(term)

    // use preview result if it matches
    if (previewResultSet && previewResultSet.searchValue === term && page === 1) {
      setCurrentResultSet(previewResultSet)
    } else {
      // otherwise make new request (may make an extra request, but logic is much simpler)
      setCurrentResultLoading(true)
      const from = (page - 1) * PLP_PAGE_SIZE
      searchRequest(term, currentFilters, currentSort, from, PLP_PAGE_SIZE)
        .then((result) => {
          logger.log(`Found ${result.results.length} results for term ${term}`)
          setCurrentResultLoading(false)
          setCurrentResultSet(result)
        })
        .catch((error) => {
          logger.error(`ElasticSearch error:\n${error}`)
          setCurrentResultLoading(false)
          setCurrentResultSet({
            error,
            results: [],
            searchValue: term,
            totalResults: 0,
          })
        })
    }
    history.push(`/products?searchTerm=${term}`)
  }

  const searchForCategory = (category: string) => {
    setCurrentResultLoading(true)
    setCurrentSearchValue(null)
    setCurrentResultSet(null)
    searchRequest(null, { 'categories.raw': category }, currentSort)
      .then((result) => {
        logger.log(result)
        setCurrentResultSet(result)
      }).catch((error) => {
        logger.error(error)
      }).finally(() => {
        setCurrentResultLoading(false)
      })
  }

  // Render Function
  return (
    <Context.Provider value={{
      currentPage,
      currentResultLoading,
      currentResultSet,
      currentSearchValue,
      currentFilters,
      currentSort,
      previewResultLoading,
      previewResultSet,
      previewSearchValue,
      searchForTerm,
      searchForCategory,
      setPreviewSearchValue,
      setCurrentFilters,
      setCurrentSort,
    }}
    >
      {children}
    </Context.Provider>
  )
}

SearchProvider.defaultProps = {
  children: null,
}

export default SearchProvider
