// flow @strict
import type { GetCatalogItemProduct_catalogItemProduct_product_metafields as Metafield } from './graphql'

const DEFAULT = 'N/A'

/*
  Parses values from RC Product metadata key-value pairs
*/
export const metafieldValueForKey = (
  metadata: ?Array<?Metafield>,
  lookupKey: string,
): string | null => {
  if (!metadata) return DEFAULT
  const entries = metadata.filter((metafield) => {
    if (!metafield) return false
    const { key } = metafield
    if (!key) return false
    return key === lookupKey
  })
  if (entries.length < 1) return DEFAULT
  const firstEntry = entries[0]
  if (!firstEntry) return DEFAULT
  const { value } = firstEntry
  if (!value) return DEFAULT

  if (value === 'null') return null

  return value
}

export const parseBoolean = (input: string | null): boolean | null => {
  if (input === null) return input
  if (input === 'true') return true

  return false
}
