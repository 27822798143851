// @flow strict
import React from 'react'

import { SearchInput } from 'search'

const SearchHeader = () => (
  <div className="w-full h-70 flex flex-col justify-center bg-dark-blue-grey">
    <div className="px-10 font-title font-normal text-4xl text-center text-white">
      Search from Thousands of Available Technologies
    </div>
    <div className="px-10 md:px-24 lg:px-44 pt-8 md:pt-10">
      <SearchInput />
    </div>
  </div>
)

export default SearchHeader
