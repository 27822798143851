import { gql } from '@apollo/client'

export const GET_SBIR = gql`
  query GetSBir ($contract: String!) {
    sbirs(where: {Contract: $contract}) {
      id
      Title
      Agency
      Branch
      Phase
      Program
      Agency_Tracking_Number
      Contract
      Proposal_Award_Date
      Contract_End_Date
      Solicitation_Number
      Solicitation_Year
      Topic_Code
      Award_Year
      Award_Amount
      DUNS
      company {
        id
        Name
        Hubzone_Owned
        Socially_Economically_Disadvantaged
        Woman_Owned
        Number_Employees
        Company_Website
        Address1
        Address2
        City
        State
        Zip
      }
      Research_Keywords
      Abstract
    }
  }
`

// const GET_PRODUCT = gql`
//   query GetCatalogItemProduct(
//     $slugOrId: String!
//   ) {
//     catalogItemProduct(slugOrId: $slugOrId) {
//       product {
//         _id
//         description
//         metafields {
//           key
//           value
//         }
//         productType
//         title
//         tags {
//           nodes {
//             _id
//             displayTitle
//           }
//         }
//         variants {
//           _id
//           attributeLabel
//           optionTitle
//           pricing {
//             compareAtPrice {
//               displayAmount
//             }
//             displayPrice
//           }
//           sku
//           title
//         }
//         vendor
//       }
//     }
//   }
// `

// export { GET_PRODUCT }
