// @flow strict
import React, { useEffect } from 'react'
import { Puff } from 'svg-loaders-react'

import type { SearchResultSet } from 'search'
import { PLP_PAGE_SIZE } from 'search'

import {
  Pagination, ResultList, FilterList, SortSelect,
} from './components'

type ProductListingPresenterProps = {
  currentPage: number,
  goToPage: (goTo: number) => void,
  loading: boolean,
  resultClicked: (slug: string) => void,
  searchResult: ?SearchResultSet,
  category?: String,
}

// TODO: responsive layout

const ProductListingPresenter = (props: ProductListingPresenterProps) => {
  const {
    currentPage,
    goToPage,
    loading,
    resultClicked,
    searchResult,
    category,
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [loading, searchResult])

  // Loading State
  if (loading) {
    return (
      <div className="w-full h-104 flex items-center justify-center bg-off-white">
        <Puff stroke="#131b41" />
      </div>
    )
  }

  // Empty State
  if (!searchResult) {
    return (
      <div className="w-full h-104 flex items-center justify-center bg-off-white">
        <span className="font-sans text-grey-text">
          {'Type in the search box above and hit \'Enter\' to get started!'}
        </span>
      </div>
    )
  }

  const { error, searchValue, totalResults } = searchResult

  const totalPages = Math.ceil(totalResults / PLP_PAGE_SIZE)

  const Header = () => {
    if (searchValue) {
      return `Results for "${searchValue}"`
    }

    if (category) {
      return `Category: ${category}`
    }

    return ''
  }

  return (
    <div className="w-full flex bg-off-white flex-col md:flex-row">
      <div className="md:w-1/3 lg:w-1/4 p-15">
        <FilterList />
      </div>
      <div className="md:w-2/3 lg:w-3/4 pl-15 md:pl-0">
        { error ? null : (
          <div className="flex pt-15 items-center">
            <div className="font-sans font-bold text-2xl text-black">
              <Header />
            </div>
            <div className="pl-3 font-sans text-sm text-black text-opacity-60">
              {totalResults}
            </div>
          </div>
        )}
        <div className="flex justify-end mr-16">
          <SortSelect />
        </div>
        <ResultList searchResult={searchResult} resultClicked={resultClicked} />
        {
          totalResults === 0 ? (<div className="pt-10" />) : (
            <Pagination currentPage={currentPage} goToPage={goToPage} totalPages={totalPages} />
          )
        }
      </div>
    </div>
  )
}

export default ProductListingPresenter
