// @flow strict
import React from 'react'
import { useHistory } from 'react-router-dom'

import searchIcon from 'assets/search.svg'
import { useSearch } from './SearchProvider'
import SearchResults from './SearchResults'

const SearchInput = () => {
  const history = useHistory()

  const {
    currentSearchValue,
    previewResultLoading,
    previewResultSet,
    searchForTerm,
    previewSearchValue,
    setPreviewSearchValue,
  } = useSearch()

  let showResultsPreview = true

  if (!previewSearchValue) showResultsPreview = false
  else if (currentSearchValue === previewSearchValue) {
    // don't show the preview results if they match the current search term
    showResultsPreview = false
  }

  const resultClicked = (contractNumber) => {
    console.log(contractNumber)
    history.push(`/product/${contractNumber}`)
  }

  return (
    <div className="relative">
      <input
        className="w-full h-15 pl-10 pr-12 rounded text-dark-grey focus:outline-none"
        onChange={(event) => setPreviewSearchValue(event.target.value)}
        onKeyUp={(event) => { if (event.key === 'Enter') searchForTerm(previewSearchValue) }}
        placeholder="coatings, textiles, substrates, etc"
        style={{ border: 'solid 1px #ececec' }}
        value={previewSearchValue}
      />
      <button
        className="absolute h-15 pr-10 right-0 top-0"
        onClick={() => searchForTerm(previewSearchValue)}
        type="submit"
      >
        <img src={searchIcon} alt="search icon" style={{ height: 18, width: 18 }} />
      </button>
      {
        showResultsPreview ? (
          <SearchResults
            loading={previewResultLoading}
            resultClicked={resultClicked}
            resultSet={previewResultSet}
            searchValue={previewSearchValue}
          />
        ) : null
      }
    </div>
  )
}

export default SearchInput
