// Product Detail Container
// @flow strict
import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import { metafieldValueForKey } from '../product-detail-page/helpers'

import VendorPresenter from './presenter'

const VendorContainer = () => {
  const params = useParams()
  const slug = _.get(params, 'slug')

  // const variables: GetCatalogItemProductVariables = {
  //   slugOrId: slug || '',
  // }

  // mock graphql response
  const data = {
    vendor: {
      _id: 'asdf',
      __typename: 'CatalogVendor',
      title: 'HYPRES',
      description: 'HYPRES develops and commercializes superconductor integrated circuits (ICs) and systems that provide unparalleled performance advantages for government and commercial applications. The flagship product is the Advanced Digital-RF Receiver (ADR), comprising superconducting digital and mixed-signal ICs packaged on a cryocooler. Using its Digital-RF technology base, the company engages in multi-faceted sponsored research and development (R&D) activities in sensors and processors for diverse applications spanning the entire electromagnetic spectrum.'
      + 'HYPRES also offers a variety of custom IC design, test and cryogenic packaging services, including superconductor and semiconductor digital, high-performance analog and mixed-signal circuits.'
      + 'Another featured HYPRES product is the Integrated Cryoelectronics Test-bed (ICE-T), a turnkey laboratory test system requiring no liquid cryogen. It provides a complete cryogenic infrastructure for broadband, variable-temperature testing of superconductor ICs and other cryogenic electronic and photonic devices at 4 K and above.',
      metafields: [
        { __typename: 'Metafield', key: 'company-name', value: 'HYPRES' },
        { __typename: 'Metafield', key: 'company-address', value: '175 Clearbrook Road | Elmsford, NY 10523' },
        { __typename: 'Metafield', key: 'company-phone', value: '888-555-1212' },
        { __typename: 'Metafield', key: 'company-url', value: 'https://www.hypres.com' },
        { __typename: 'Metafield', key: 'company-businessContact-name', value: 'Donald L. Forrester' },
        { __typename: 'Metafield', key: 'company-businessContact-phone', value: '123-456-7890' },
        { __typename: 'Metafield', key: 'company-businessContact-email', value: 'donald.forrester@hypres.com' },
      ],
      products: [
        {
          __typename: 'CatalogProduct',
          metafields: [
            { __typename: 'Metafield', key: 'agency', value: 'Defense' },
            { __typename: 'Metafield', key: 'branch', value: 'Air Force' },
            { __typename: 'Metafield', key: 'phase', value: 'Phase II' },
          ],
          title: 'Laser irradiation induced non-skid surface layer formation on substrate',
        },
        {
          __typename: 'CatalogProduct',
          metafields: [
            { __typename: 'Metafield', key: 'agency', value: 'Defense' },
            { __typename: 'Metafield', key: 'branch', value: 'Air Force' },
            { __typename: 'Metafield', key: 'phase', value: 'Phase II' },
          ],
          title: 'Eco-friendly, safe, anti-corrosive, inexpensive, active aluminum-rich primers',
        },
        {
          __typename: 'CatalogProduct',
          metafields: [
            { __typename: 'Metafield', key: 'agency', value: 'Defense' },
            { __typename: 'Metafield', key: 'branch', value: 'Air Force' },
            { __typename: 'Metafield', key: 'phase', value: 'Phase II' },
          ],
          title: 'Rolling textile protective system for textile structural members',
        },
        {
          __typename: 'CatalogProduct',
          metafields: [
            { __typename: 'Metafield', key: 'agency', value: 'Defense' },
            { __typename: 'Metafield', key: 'branch', value: 'Air Force' },
            { __typename: 'Metafield', key: 'phase', value: 'Phase II' },
          ],
          title: 'Laser Diode End Pumped Monoblock Laser',
        },
      ],
    },
  }

  // const { data, error, loading }: {
  //   data: ?GetCatalogItemProduct,
  //   error: Error,
  //   loading: Boolean,
  // } = useQuery(GET_VENDOR, { variables })

  // if (loading) {
  //   return (
  //     <div style={{ padding: 40 }}>Loading...</div>
  //   )
  // }

  // if (error) {
  //   return (
  //     <div style={{ padding: 40 }}>
  //       <h4>Error Loading Vendor</h4>
  //       <p>{error.message}</p>
  //       <p>{error.description}</p>
  //     </div>
  //   )
  // }

  const NotFoundMarkup = (
    <div style={{ padding: 40 }}>Not Found</div>
  )

  if (!data) return NotFoundMarkup

  // const catalogItemProduct = _.get(data, 'catalogItemProduct')
  // if (!catalogItemProduct) return NotFoundMarkup

  // const product = _.get(catalogItemProduct, 'product')
  // if (!product) return NotFoundMarkup

  const { vendor } = data

  const {
    _id,
    description,
    metafields,
    title,
  } = vendor

  // Company metafields
  const companyName = metafieldValueForKey(metafields, 'company-name')
  const companyAddress = metafieldValueForKey(metafields, 'company-address')
  const companyPhone = metafieldValueForKey(metafields, 'company-phone')
  const companyUrl = metafieldValueForKey(metafields, 'company-url')

  const businessContactName = metafieldValueForKey(metafields, 'company-businessContact-name')
  const businessContactPhone = metafieldValueForKey(metafields, 'company-businessContact-phone')
  const businessContactEmail = metafieldValueForKey(metafields, 'company-businessContact-email')

  const products = vendor.products.map((product) => (
    {
      title: product.title,
      agency: metafieldValueForKey(product.metafields, 'agency'),
      branch: metafieldValueForKey(product.metafields, 'branch'),
      phase: metafieldValueForKey(product.metafields, 'phase'),
    }
  ))

  return (
    <VendorPresenter
      title={companyName}
      businessContactName={businessContactName}
      businessContactPhone={businessContactPhone}
      businessContactEmail={businessContactEmail}
      companyName={companyName}
      companyAddress={companyAddress}
      companyPhone={companyPhone}
      companyUrl={companyUrl}
      description={description}
      products={products}
    />
  )
}

export default VendorContainer
