// @flow strict
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useSearch } from 'search'

import ProductListingPresenter from './presenter'
import { SearchHeader } from './components'

/*
  A note on managing search state:

  This page first loads under a number of different 'search states':
  1. No search term provided
  2. Search term provided & result set already loaded
  3. Search term provided & result set is loading
  4. Search term provided & no result set & not loading (page refresh for example)
  You cant tell if a search term was provided by checking the URL query parameter 'searchTerm'
  Cases 1-3 are easier and involve reflecting the state of the SearchProvider to the user.
  Case 4 requires some logic to identify, at which point the search API call must be made.
*/

// TODO: sorting
// TODO: filtering

const ProductListingContainer = () => {
  const history = useHistory()
  const location = useLocation()

  const {
    currentPage,
    currentResultLoading,
    currentResultSet,
    currentSearchValue,
    searchForTerm,
    searchForCategory,
  } = useSearch()

  // make search API call if needed
  const searchParams = new URLSearchParams(location.search)
  const searchTerm = searchParams.get('searchTerm')

  const { category } = queryString.parse(location.search)

  useEffect(() => {
    if (!currentResultLoading && !currentResultSet) {
      if (searchTerm) {
        searchForTerm(searchTerm)
      }

      if (category) {
        searchForCategory(category)
      }
    }
  }, [currentResultLoading, currentResultSet, searchForTerm, searchTerm, category, currentSearchValue])

  // Action Handlers
  const goToPage = (goTo: number) => {
    // Disable pagination while loading to prevent race condition and out of order
    // network response bugs
    if (currentResultLoading || goTo === currentPage) {
      return
    }
    searchForTerm(currentSearchValue, goTo)
  }

  const resultClicked = (contract: string) => {
    history.push(`/product/${contract}`)
  }

  return (
    <>
      <SearchHeader />
      <ProductListingPresenter
        currentPage={currentPage}
        goToPage={goToPage}
        loading={currentResultLoading}
        resultClicked={resultClicked}
        searchResult={currentResultSet}
        category={category}
      />
    </>
  )
}

export default ProductListingContainer
